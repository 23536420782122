/*
 * @Author: wangqs 
 * @description 完善最后一步信息
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-04-23 15:31:25
 */
<template>
  <div class="page-box box">
    <div class="top-box">
      <div class="text-a">
        最后一步咯！
      </div>
      <div class="text-b">
        完善信息，以便于您进行分期业务
      </div>
    </div>

    <!--  -->
    <div
      class="box"
      style="padding-top: 10px;"
    >
      <template v-for="(val, key) in form">
        <div
          :key="key"
          class="upload-box"
        >
          <div class="title">
            <span
              v-if="key === 'offlineProtocolList' || key === 'propertyCertificateList' || key === 'receiptList' || (key === 'marriedUrlList' && orderInfo.married == 2) || (key === 'parentageList' && orderInfo.totalAmount >= 10000)"
              style="color: #D5423E;"
            >*</span>
            {{ formObj[key] || '亲子证明' }}
          </div>
          <div class="upload-btn">
            <van-uploader
              v-model="form[key]"
              :after-read="(query)=>{afterRead(query, key)}"
              :max-size="20 * 1024 * 1024"
              @oversize="onOversize"
              @delete="(e)=>{deleteItem(e, key)}"
            >
            </van-uploader>
          </div>
        </div>
      </template>
    </div>

    <van-button
      class="submit-btn"
      color="#51B464"
      round
      type="info"
      @click="nextfun"
    >
      提交
    </van-button>
    <!-- 人脸识别 -->
    <form
      :action="faceData.action || 'https://dsptest.cpcn.com.cn/DSPLivenessWeb/InterfaceVI'"
      name="faceForm"
      style="opacity: 0;position: absolute;left: -2000px;top:0;"
      method="POST"
    >
      <div>
        <input
          type="text"
          name="message"
          placeholder="message"
          :value="faceData.message"
        />
      </div>
      <div>
        <input
          type="text"
          name="signature"
          placeholder="signature"
          :value="faceData.signature"
        />
      </div>
      <div>
        <input
          id="dgtlEnvlp"
          type="text"
          name="dgtlenvlp"
          placeholder="dgtlenvlp"
          :value="faceData.dgtlenvlp"
        />
      </div>
      <div>
        <input
          id="signSN"
          type="text"
          name="signSN"
          placeholder="signSN"
          :value="faceData.signSN"
        />
      </div>
      <div>
        <input
          id="encryptSN"
          type="text"
          name="encryptSN"
          placeholder="encryptSN"
          :value="faceData.encryptSN"
        />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'LastInfo',
  components: {
  },
  data () {
    return {
      show: true,
      faceData: {},
      formObj: {
        offlineProtocolList: '线下原始协议',
        propertyCertificateList: '财产证明',
        receiptList: '收据',
        parentageList: '亲子关系证明(贷款金额大于1万必填)',
        marriedUrlList: '结婚证'
        
      },
      form: {
        offlineProtocolList: [], // 线下原始协议
        propertyCertificateList: [], // 财产证明
        receiptList: [], // 收据
        parentageList: [], // 亲子证明
        marriedUrlList: []
      },
      orderInfo: {},
    }
  },
  computed: {
  },
  created () {
    let orderInfo = JSON.parse(window.localStorage.getItem('orderInfo') || '{}')
    this.orderInfo = orderInfo
    this.faceBackFunc()
  },
  methods: {
    afterRead (file, key) {
      console.log(file)
      let vm = this;
      var fd = new FormData()
      // fd.append('Content-Type', 'multipart/form-data')
      fd.append('multipartFile', file.file)
      fd.append('dir', 'org')
      vm.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      var xhr = new XMLHttpRequest()
      xhr.open('POST', '/gateway/img/upload', true)
      xhr.onload = function () {
        if (Math.floor(xhr.status / 100) === 2) {
          var res = JSON.parse(xhr.response)
          if (res.code === 200) {
            console.log(res, '上传成功')
            file.url = res.msg.url || ''
          } else {
            vm.$toast.clear()
            vm.$toast('上传失败，请检查后重新上传')
          }
        } else {
          // clearInterval(vm.timerClock)
          vm.$toast.clear()
          vm.$toast('上传失败')
        }
        vm.$toast.clear()
      }
      xhr.onerror = function () {
        vm.$toast.clear()
        vm.$toast.fail('文件 ' + params.file.name + ' 上传失败')
      }
      xhr.setRequestHeader('token', JSON.parse(window.localStorage.getItem('userData') || '{}').token)
      xhr.send(fd)
    },
    onOversize (file) {
      console.log(file);
      this.$toast('文件大小不能超过 2M');
    },
    async nextfun () {
      let obj = {}
      if (this.form['offlineProtocolList'].length === 0) {
        this.$toast('请完善线下原始协议')
        return
      }
      if (this.form['propertyCertificateList'].length === 0) {
        this.$toast('请完善财产证明')
        return
      }
      if (this.form['receiptList'].length === 0) {
        this.$toast('请完善收据')
        return
      }
      if (this.form['parentageList'].length === 0 && this.orderInfo.totalAmount >= 10000) {
        this.$toast('请完善亲子证明关系')
        return
      }
      if (this.form['marriedUrlList'].length === 0 && this.orderInfo.married == 2) {
        this.$toast('请完善结婚证')
        return
      }
      for (let i in this.form) {
        if (this.form[i].length > 0) {
          obj[i] = []
          for (let j of this.form[i]) {
            obj[i].push(j.url)
          }
          this.orderInfo[i] = obj[i]
        }
      }
      window.localStorage.setItem('orderInfo', JSON.stringify(this.orderInfo))
      // 分期支付 /school/orderPay
      // 全款 /school/coursePay
      // this.$router.push('/school/orderPay?id=' + this.$route.query.id)
      let url = 'orderCreate'
      this.$toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      });
      let res = await this.$http.orderApi[url](this.orderInfo)
      this.$toast.clear()
      if (res.code === 200) {
        this.$toast.success('提交成功')
        console.log(res, 'res')
        window.localStorage.setItem('orderApiRes', JSON.stringify(res.msg || {}))
        // 人脸识别校验
        if (this.orderInfo.face) {
          if (this.orderInfo.faceNo) {
            let resB = await this.$http.orderApi.orderFaceParams({id: this.orderInfo.faceNo})
            if (resB.code === 200) {
              this.faceData = resB.msg || {}
              this.orderNo = res.msg.orderNo || {}
              this.$nextTick(() => {
                document.faceForm && document.faceForm.submit()
              })
            }
          }
        }
        return
      }
    },
    faceBackFunc () {
      if (this.$route.query.status == 1) {
        this.$toast.loading({
          message: '加载中',
          forbidClick: true,
          duration: 0
        });
        let msg = JSON.parse(window.localStorage.getItem('orderApiRes') || '{}')
        if (this.orderInfo.loanType == 2 && msg.contractUrl) {
          // 去签署
          let data = msg || {}
          window.localStorage.setItem('pdfOrder', JSON.stringify(data))
          this.$router.push('/order/sign?type=bank&id=' + data.orderNo)
        } else {
          window.localStorage.removeItem('orderInfo')
          window.localStorage.removeItem('courseDetail')
          window.localStorage.removeItem('userCheckInfo')
          window.localStorage.removeItem('faceOrderNo')
          this.$router.replace('/order/success')
        }
        this.$toast.clear()
        window.localStorage.removeItem('orderApiRes')
      }
    },
    deleteItem (e, key) {
      console.log(e, key)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding-bottom: 76px;
  .top-box {
    height: 166px;
    background: url('~@/assets/img/page-bg.png') center center no-repeat;
    background-size: 100% 192px;
    padding-top: 88px;
    padding-left: 15px;
    .text-a {
      font-size: 17px;
      font-weight: bold;
      line-height: 40px;
    }
    .text-b {
      color: #7c7c7c;
      line-height: 16px;
      font-size: 13px;
    }
  }
  .submit-btn {
    width: 346px;
    position: fixed;
    left: 50%;
    margin-left: -173px;
    bottom: 20px;
    display: block;
  }
  .upload-box {
    padding-left: 15px;
    .title {
      margin-bottom: 8px;
      font-size: 14px;
    }
  }
}
</style>
